<script setup>
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import Button from '@/Components/Button/Button.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    query: Object,
    companies: Object,
    userTypeOptions: Object,
});

const form = useForm({
    page: props.query.page ?? 1,
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',
});

const typeFilters = {};

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.companies.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['companies', 'query'],
    });
};

const tableSettings = [
    { label: t('Name'), orderBy: 'commercial_name', width: '15%' },
    { label: t('KvK number'), width: '10%' },
    { label: t('VAT number'), width: '10%' },
    { label: t('Owner'), width: '10%' },
    { label: t('Type'), width: '10%' },
    { label: '', cell: 'Arrow', width: '2%' },
];

const tableData = computed(() =>
    props.companies.data.map((company) => {
        return {
            id: company.id, // used as vue key if set
            rowData: [
                company.commercial_name,
                company.kvk_number,
                company.vat_number,
                company?.owner?.nickname ?? '---',
                props.userTypeOptions[company?.owner?.type],
                route('staff.companies.edit', company.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('Companies')">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            :h1="$t('Companies')"
            :data="companies"
            :typeFilters="typeFilters"
            :hasSearch="false"
        >
            <Button
                blue
                icon="/icon/download-excel.svg"
                type="button"
                asA
                class="ml-2"
                :href="route('staff.company-data.download')"
                :text="$t('Export')"
            />
        </Filters>
        <template v-if="typeof companies !== 'undefined' && companies.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof companies !== 'undefined'"
                :links="companies.links"
                @setPage="setPage"
                :pageData="companies"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('companies') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
